import {Theme as MuiTheme} from "@mui/material/styles/createTheme";
import gray from "@mui/material/colors/grey";
import {isBoolean} from "lodash";
import {blueGrey} from "@mui/material/colors";
import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";

export const CARD_BORDER_RADIUS = 20

type Theme = MuiTheme | boolean;

export const isLight = (t: Theme) => {
    if (isBoolean(t)) {
        return t
    }
    return t.palette.mode === 'light'
}

export const gColor = (t: Theme, lightColor: string, darkColor: string) => isLight(t) ? lightColor : darkColor

export const getCardBg = (theme: Theme | boolean) => isLight(theme) ? "#FCFCFC" : gray[800]
export const getBgGrayCard = (theme: Theme | boolean) => isLight(theme) ? "#fff" : gray[800]
export const getBgGrayCardHover = (theme: Theme) => isLight(theme) ? gray[200] : gray["A700"]

export const getBgGrayInsideCard = (theme: Theme | boolean) => isLight(theme) ? gray[100] : gray[900]

export const getGradiantPrimary = (theme: Theme, deg: number = 310) => isLight(theme) ?
    `linear-gradient(${deg}deg,  #FFCA63 0%,#FFBC66 100%)` :
    `linear-gradient(${deg}deg,  #FFCA63 0%,#FFBC66 100%)`

export const getGradiantSecondary = (theme: Theme, deg: number = 131) => isLight(theme) ?
    `linear-gradient(${deg}deg,  #c0d86c 0%, #e2f57a 100%)` :
    `linear-gradient(${deg}deg, #c0d86c 0%, #e2f57a 100%)`


export const PaymentButton = styled(Button)(({theme}) => ({
    background: blueGrey[800],
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 600,
    borderRadius: "5px",
    color: blueGrey[100],
    "&:hover": {
        background: blueGrey[700],
    }

}))


export const getToolbarHeight = (): number => (window.document.getElementById("mainAppBar")?.offsetHeight) as number

