import React, {useEffect, useState} from "react"
import {BrowserRouter, Routes,} from "react-router-dom";
import {BaseROUTS, RoutGenerator} from "../../common/routs/ROUTS";
import {Middleware, SWRConfig, SWRHook} from "swr"
import {GET} from "../../common/api/APIService";
import {getBaseApi} from "../../common/api/API";
import {ThemeProvider} from "@mui/material/styles";
import {createTheme} from "@mui/material";
import LocalStorage from "../../common/LocalStorage";
import {useUser} from "../../redux/reducers/userReducer";
import ServiceWorkerWrapper from "../../wrapper/ServiceWorkerWrapper";
import {ThemeMode} from "../../common/TYPE";
import useBaseTheme from "../../common/theme/useBaseTheme";


declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        xsm: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        xxl: true;
    }
}

export const fetcher = (url: string) => url ? GET(getBaseApi(url)).then((res) => res?.data) : null;

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
});

const THEME_STORAGE = "user-theme-mode"

const muiTheme = createTheme()

let studentId: undefined | number = undefined


const swrMiddleware: Middleware = (useSWRNext: SWRHook) => (key, fetcher, config) => {
    return useSWRNext([key, (studentId || "")], fetcher, config)
}

export default function Base() {
    const user = useUser()

    const [showSite, setShowSite] = useState(true)
    const [mode, setMode] = React.useState<ThemeMode>(LocalStorage.get(THEME_STORAGE, 'light'));
    const theme = useBaseTheme({mode})

    useEffect(() => {
        studentId = user?.student_id
    }, [user])

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    const newMode = (prevMode === 'light' ? 'dark' : 'light');
                    LocalStorage.set(THEME_STORAGE, newMode)
                    return newMode
                });
            },
        }),
        [],
    );

    // if (!showSite && isProduction)
    //     return <Hidden onShow={setShowSite}/>

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <SWRConfig
                    value={{
                        fetcher,
                        refreshInterval: 0,
                        revalidateOnFocus: true,
                        dedupingInterval: 10000,
                        shouldRetryOnError: true,
                        revalidateIfStale: false,
                        refreshWhenOffline: true,
                        errorRetryCount: 2,
                        use: [swrMiddleware]
                    }}
                >
                    <ServiceWorkerWrapper/>
                    <BrowserRouter>
                        <Routes>
                            {RoutGenerator(BaseROUTS)}
                        </Routes>
                    </BrowserRouter>
                </SWRConfig>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}
