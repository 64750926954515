import _ from "lodash";

const LocalStorage = {
  set: (key: string, value: any): void => {
    if (_.isBoolean(value)) {
      value = { val: value ? "true" : "false", localStorageBoolType: true };
    }
    if (_.isArray(value)) {
      value = { val: value, localStorageArrayType: true };
    }
    if (_.isObject(value)) {
      value = JSON.stringify(value);
    }
    return localStorage.setItem(key, value);
  },
  get: (key: string, defaultValue?: any): any => {
    const val = localStorage.getItem(key) as string;
    try {
      const res = JSON.parse(val);
      if (res?.localStorageBoolType) {
        return res.val === "true";
      }
      if (res?.localStorageArrayType) {
        return res.val;
      }
      if (res === null) return defaultValue;
      return res;
    } catch {
      return val;
    }
  },
  remove: (key: string): void => {
    return localStorage.removeItem(key);
  },
};

export class LocalStorageHandler<T> {
  key = "";
  constructor(storageKey: string) {
    this.key = storageKey;
  }
  get(): T {
    return LocalStorage.get(this.key) as T;
  }
  set(value: T): void {
    return LocalStorage.set(this.key, value);
  }
  remove(): void {
    return LocalStorage.set(this.key, null);
  }
}

export default LocalStorage;
