import {blueGrey, grey} from "@mui/material/colors";
import {Color} from "./type";
import {get} from "./utils";
import gray from "@mui/material/colors/grey";


export const primary = {
    light: "#ffd074",
    main: "#F08049",
    dark: "#f08049",
    contrastText: gray[800],
    get
}

export const secondary = {
    light: "#bade87",
    main: "#84BF62",
    dark: "#9cd15a",
    contrastText: gray[800],
    get
}


//region textColor
export const textColorMenu: Color = {
    light: "#FFFFFF",
    dark: blueGrey[100],
    get
};

//region textColor
export const textColor: Color = {
    light: "#444444",
    dark: blueGrey[100],
    get
};

export const textColor2: Color = {
    light: blueGrey[600],
    dark: blueGrey[200],
    get
};
export const textColor3: Color = {
    light: "#787878",
    dark: "#787878",
    get
};
export const textColor4: Color = {
    light: "#444444",
    dark: blueGrey[200],
    get
};
export const textLightColor: Color = {
    light: "#FFFFFF",
    dark: "#787878",
    get
};


export const secondaryTextColor: Color = {
    light: "#78a14b",
    dark: "#9ccb69",
    get
}

//endregion textColor

//region backgroundColor

export const primaryBg: Color = {
    light: "#ffdc9f",
    dark: "#ffdc9f",
    get
}

export const secondaryBg: Color = {
    light: "#ccefb7",
    dark: "#ccefb7",
    get
}

export const bgColor: Color = {
    light: "#fff",
    dark: gray[800],
    get
}

export const bgColor2: Color = {
    light: "#fcfcfc",
    dark: gray[900],
    get
}

export const bgColor3: Color = {
    light: gray[200],
    dark: gray["A700"],
    get
}

export const siteBg: Color = {
    light: "#ffffff",
    dark: grey[900],
    get
}
//endregion backgroundColor

//region gradientColor

export const gradiantPrimary: Color = {
    light: `linear-gradient(310deg,  ${primary.light} 0%,${primary.dark} 100%)`,
    dark: `linear-gradient(310deg,  ${primary.light} 0%,${primary.dark} 100%)`,
    get
}

export const gradiantSecondary: Color = {
    light: `linear-gradient(131deg,  ${secondary.light} 0%, ${secondary.dark} 100%)`,
    dark: `linear-gradient(131deg,  ${secondary.light} 0%, ${secondary.dark} 100%)`,
    get
}
//endregion gradientColor

