import { defaultToken } from '../reducers/tokenReducers'

export const UPDATE_TOKEN = 'T_UPDATE_TOKEN'
export const REMOVE_TOKEN = 'T_REMOVE_TOKEN'
export const REFRESH_TOKEN = 'T_REFRESH_TOKEN'

export const updateToken = (accessToken, refreshToken, studentPortalToken) => ({
  type: UPDATE_TOKEN,
  payload: {
    accessToken,
    refreshToken,
    studentPortalToken,
  },
})

export const removeToken = () => ({
  type: REMOVE_TOKEN,
  payload: {
    accessToken: defaultToken.accessToken,
    refreshToken: defaultToken.refreshToken,
    studentPortalToken: defaultToken.studentPortalToken,
  },
})

export const refreshToken = () => {
  //TODO: request and save new token
  return {
    type: REFRESH_TOKEN,
    payload: {
      accessToken: defaultToken.accessToken,
      refreshToken: defaultToken.refreshToken,
    },
  }
}
