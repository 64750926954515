import {defaultUserData} from '../reducers/userReducer'

export const UPDATE_USER = 'U_UPDATE_USER'
export const REMOVE_USER = 'U_REMOVE_USER'

export const updateUser = ({
                               student_id,
                               student_firstname,
                               student_lastname,
                               username,
                               email,
                               image,
                               nationality_id,
                               student_address,
                               student_alternative_phone,
                               student_birthday,
                               student_gender,
                               student_name,
                               student_passport_no,
                               student_phone,
                               student_school,
                               student_school_end_date,
                               student_school_start_date
                           }, mutate) => ({
    type: UPDATE_USER,
    payload: {
        student_id,
        student_firstname,
        student_lastname,
        username,
        email,
        image,
        nationality_id,
        student_address,
        student_alternative_phone,
        student_birthday,
        student_gender,
        student_name,
        student_passport_no,
        student_phone,
        student_school,
        student_school_end_date,
        student_school_start_date,
        mutate
    },
})

export const removeUser = () => ({
    type: REMOVE_USER,
    payload: defaultUserData,
})
