import { TOGGLE_DARK_MODE } from "../actions/darkModeAction";

const initialState = {
  dark: false,
};

const sideBarReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case TOGGLE_DARK_MODE:
      return { ...state, dark: !state.dark };
    default:
      return state;
  }
};

export default sideBarReducer;
