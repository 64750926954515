import {REMOVE_TOKEN, removeToken, UPDATE_TOKEN,} from "../actions/tokenAction";
import {getStore} from "../store";
import localStorage from "../../common/LocalStorage";
import {removeUser} from "../actions/userAction";
import {accessTokenCookie, refreshTokenCookie, studentPortalTokenCookie,} from "../../common/Cookie";
import {tryIt, tryItWithDefault} from "../../common/utils";

export const defaultToken = {
    accessToken: accessTokenCookie.get(),
    refreshToken: refreshTokenCookie.get(),
    studentPortalToken: null,
};

const tokenReducers = function (state = defaultToken, {type, payload}) {
    switch (type) {
        case UPDATE_TOKEN: {
            const {
                accessToken = state.accessToken,
                refreshToken = state.refreshToken,
                studentPortalToken = state.studentPortalToken,
            } = payload;

            accessTokenCookie.set(accessToken);
            refreshTokenCookie.set(refreshToken);
            studentPortalTokenCookie.set(studentPortalToken);
            return {
                ...state,
                accessToken,
                refreshToken,
                studentPortalToken,
            };
        }
        case REMOVE_TOKEN:
            accessTokenCookie.remove();
            refreshTokenCookie.remove();
            studentPortalTokenCookie.set("");
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default tokenReducers;

export function getAccessToken() {
    return (
        tryItWithDefault(
            () => getStore().getState().token.accessToken,
            defaultToken.accessToken
        ) ||
        tryIt(() => JSON.parse(localStorage.get("persist:root").token).accessToken)
    );
}

export function getRefreshToken() {
    return (
        tryItWithDefault(() => getStore().getState().token.refreshToken, defaultToken.refreshToken) ||
        tryIt(() => JSON.parse(localStorage.get("persist:root").token).refreshToken)
    );
}

export function getStudentPortalToken() {

    return (
        tryItWithDefault(() => getStore().getState().token.studentPortalToken, defaultToken.studentPortalToken) ||
        tryIt(() => JSON.parse(localStorage.get("persist:root").token).studentPortalToken)
    );
}

export function logout(dispatch) {
    const accessTokenCookieBackup = accessTokenCookie.get()
    accessTokenCookie.set("");
    refreshTokenCookie.set("");

    if (accessTokenCookieBackup)
        window.location.reload();


    const disp = dispatch || getStore().dispatch;
    disp(removeToken());
    disp(removeUser());
}
