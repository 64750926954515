import { combineReducers } from "redux";
import userReducers from "./userReducer";
import tokenReducers from "./tokenReducers";
import sidebarReducer from "./sidebarReducer";
import darkModeReduce from "./darkModeReduce";

const rootReducer = combineReducers({
  token: tokenReducers,
  user: userReducers,
  sidebar: sidebarReducer,
  darkMode: darkModeReduce,
});

export default rootReducer;
