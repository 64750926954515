import React from "react";
import Login from "../../pages/Login";
import {useNavigate} from "react-router-dom";

export default function AuthBase() {
    const navigator = useNavigate();
    // const user = useSelector((state: RootState) => state.user);

    // useEffect(() => {
    //     if (_.isEmpty(user)) return;
    //     navigator(ROUTS.PRIVATE.rout);
    // }, [navigator, user]);

    return (
        <React.Fragment>
            <Login />
        </React.Fragment>
    );
}
