export const UPDATE_SIDEBAR = "SIDE_UPDATE_SIDEBAR";
export const TOGGLE_SIDEBAR = "SIDE_TOGGLE_SIDEBAR";

export const openSideBar = () => ({
  type: UPDATE_SIDEBAR,
  payload: {
    sidebar: true,
  },
});

export const closeSideBar = () => ({
  type: UPDATE_SIDEBAR,
  payload: {
    sidebar: false,
  },
});

export const toggleSideBar = () => ({
  type: TOGGLE_SIDEBAR,
});
