import Axios, {AxiosRequestConfig} from "axios";
import API from "./API";
import qs from "qs";
import CustomToast from "../ToastGenerator";
import {getStore} from "../../redux/store";
import {updateToken} from "../../redux/actions/tokenAction";
import {getAccessToken, getRefreshToken, logout,} from "../../redux/reducers/tokenReducers";

export type APIMethodType = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
export const APIServiceMethod: {
    GET: APIMethodType;
    POST: APIMethodType;
    PUT: APIMethodType;
    DELETE: APIMethodType;
    PATCH: APIMethodType;
} = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
};

export function isAPIMethodType(type: APIMethodType): type is APIMethodType {
    return Object.values<APIMethodType>(APIServiceMethod).indexOf(type) !== -1;
}

type headerType = {
    Authorization?: string;
    XRefreshToken?: string;
};
type requestDataType = {
    headers?: headerType;
    body?: any;
    params?: any;
    options?: any;
};
type requestOptionType = {
    useClientSecret?: boolean;
};

const APIService = async (
    url: string,
    method: APIMethodType,
    requestData: requestDataType | undefined = {
        headers: {},
        body: {},
        params: {},
        options: {},
    },
    requestOptions: requestOptionType = {useClientSecret: true}
) => {
    let headers: headerType = {},
        body = {},
        params = {},
        options = {};

    /* input validation */
    /* url validation */
    if (!url) {
        throw "APIService ERROR: url is not defined correctly";
    }
    /* method validation */
    if (!isAPIMethodType(method)) {
        throw "API CALL ERROR: API call method is not defined correctly";
    }
    if (requestData) {
        if (requestData.headers) {
            headers = requestData.headers as headerType;
        }
        if (requestData.body) {
            body = requestData.body;
        }
        if (requestData.params) {
            params = requestData.params;
        }
        if (requestData.options) {
            options = requestData.options;
        }
    }

    if (requestOptions.useClientSecret) {
        const accessToken = getAccessToken();
        headers.Authorization = accessToken ? "Bearer " + accessToken : "";
        const refreshToken = getRefreshToken();
        headers.XRefreshToken = refreshToken ? refreshToken : "";
    }

    const api = Axios.create({
        baseURL: process.env.REACT_APP_BASE_API,
        headers: headers,
        params: params,
        data: method !== APIServiceMethod.GET ? body : {},
        ...options,
    });

    api.interceptors.request.use(
        async (config: AxiosRequestConfig) => {
            const accessToken = ""
            if (accessToken) {
                config.headers = {
                    ...config.headers,
                    authorization: `Bearer ${accessToken}`,
                };
            }

            return config;
        },
        (error) => Promise.reject(error)
    );

    let response;
    switch (method) {
        case APIServiceMethod.GET:
            response = api.get(url, {
                paramsSerializer: function (params) {
                    return qs.stringify(params, {arrayFormat: "repeat"});
                },
            });
            break;

        case APIServiceMethod.POST:
            response = api.post(url, body);
            break;

        case APIServiceMethod.PUT:
            response = api.put(url, body);
            break;

        case APIServiceMethod.DELETE:
            response = api.delete(url);
            break;

        case APIServiceMethod.PATCH:
            response = api.patch(url, body);
            break;

        default:
            return;
    }
    return await response
        .then((response) => {
            if (response.data && response.data?.notification) {
                CustomToast(
                    response.data.notification?.message,
                    response.data.notification?.type,
                    {
                        autoClose: response.data.notification?.autoClose,
                    }
                );
            }

            if (response?.data?.access || response?.data?.refresh) {
                const {access, refresh, student_portal_token} = response?.data;
                getStore().dispatch(updateToken(access, refresh, student_portal_token));
            }

            return response;
        })
        .catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                logout();
            }

            if (err?.response?.data?.notification && err?.response?.data?.notification?.message !== "an unexpected error happened") {
                CustomToast(
                    err?.response?.data?.notification?.message,
                    err?.response?.data?.notification?.type,
                    {
                        autoClose: err?.response?.data?.notification?.autoClose,
                    }
                );
            }

            throw err;
        });
};

export const GET = async (
    url: string,
    requestData: requestDataType | undefined = {
        headers: {},
        body: {},
        params: {},
        options: {},
    },
    requestOptions?: requestOptionType
) => {
    return APIService(url, APIServiceMethod.GET, requestData, requestOptions);
};

export const POST = async (
    url: string,
    requestData: requestDataType | undefined = {
        headers: {},
        body: {},
        params: {},
        options: {},
    },
    requestOptions?: requestOptionType
) => {
    return APIService(url, APIServiceMethod.POST, requestData, requestOptions);
};

export const PUT = async (
    url: string,
    requestData: requestDataType | undefined = {
        headers: {},
        body: {},
        params: {},
        options: {},
    },
    requestOptions?: requestOptionType
) => {
    return APIService(url, APIServiceMethod.PUT, requestData, requestOptions);
};

export default APIService;
