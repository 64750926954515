import {getBaseDomain, tryIt} from "./utils";

const BASE_DOMAIN = getBaseDomain()

export function setCookie(
    cname: string,
    value: string,
    {
        exdays = 1,
        domain = BASE_DOMAIN,
        path = "/"
    } = {}) {
    try {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + value + ";" + expires + ";path=" + path + ";domain=" + domain;
    } catch {
    }
}

export function removeCookie(
    cname: string,
    {
        domain = BASE_DOMAIN,
        path = "/"
    } = {}) {
    return setCookie(cname, "", {
        exdays: -5,
        domain,
        path
    })
}


export function getCookie(cname: string) {
    return tryIt<string>(() => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';').map(element => {
            return element.trim();
        });

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                const val = c.substring(name.length, c.length)
                if (val)
                    return val;
            }
        }
        return "";
    })
}

export const accessTokenCookie = {
    set: (value: string) => setCookie("access", value, {
        domain: BASE_DOMAIN
    }),
    remove: () => removeCookie("access", {
        domain: BASE_DOMAIN
    }),
    get: () => getCookie("access")
}

export const refreshTokenCookie = {
    set: (value: string) => setCookie("refresh", value, {
        exdays: 60,
        domain: BASE_DOMAIN
    }),
    remove: () => removeCookie("refresh", {
        domain: BASE_DOMAIN
    }),
    get: () => getCookie("refresh")
}
export const studentPortalTokenCookie = {
    set: (value: string) => setCookie("student-portal-token", value, {
        exdays: 60,
        domain: BASE_DOMAIN
    }),
    get: () => getCookie("student-portal-token")
}


