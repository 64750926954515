import {createStore} from "redux";
import rootReducer from "./reducers/rootReducer";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["token","user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer);

const storeRoot = () => {
  // const store = createStore(persistedReducer)
  const persistor = persistStore(store);
  return { store, persistor };
};

export default storeRoot;

export function getStore() {
  return store;
}
