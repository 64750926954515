import {isBoolean} from "lodash";
import {Color, Theme} from "./type";


export const isLight = (t: Theme) => {
    if (isBoolean(t)) {
        return t
    }
    return t.palette.mode === 'light'
}

export const gColorWithColorObject = (t: Theme, color: Color): string => {
    return isLight(t) ? color.light : color.dark
}

export const gColor = (t: Theme, lightColor: string, darkColor: string): string => {
    return isLight(t) ? lightColor : darkColor
}

export function get(this: Color, theme: Theme): string {
    return gColorWithColorObject(theme, this)
}


export const getToolbarHeight = (): number => (window.document.getElementById("mainAppBar")?.offsetHeight) as number
