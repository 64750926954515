import React from "react";
import {primary, secondary, textColor} from "./color";
import shadows, {Shadows} from "@mui/material/styles/shadows";
import {createTheme, responsiveFontSizes} from "@mui/material";
import {CARD_BORDER_RADIUS, gColor, getBgGrayCard, getCardBg} from "./index";
import {ThemeMode} from "../TYPE";


const muiTheme = createTheme()


const useBaseTheme = ({mode}: { mode: ThemeMode }) => {


    return React.useMemo(
        () => {
            const isLight = mode === "light";
            const colors = {
                primary: primary,
                secondary: secondary,
            }

            const newShadows = [...shadows] as Shadows
            newShadows[1] = "0 2px 5px 2px rgb(0 0 2 / 5%)";
            newShadows[2] = "0 2px 5px 2px rgb(0 0 2 / 5%)";
            newShadows[3] = "0 2px 5px 2px rgb(0 0 2 / 5%)";


            const theme = createTheme({
                palette: {
                    ...colors,
                    mode,
                },
                breakpoints: {
                    values: {
                        xs: 0,
                        xsm: 400,
                        sm: 600,
                        md: 900,
                        lg: 1200,
                        xl: 1536,
                        xxl: 1700,
                    },
                },
                shadows: newShadows,
                typography: {
                    allVariants: {}
                },
                components: {
                    MuiTypography: {
                        styleOverrides: {
                            root: ({ownerState}) => ({
                                color: textColor.get(isLight)
                            })
                        }
                    },
                    MuiButton: {
                        styleOverrides: {
                            root: ({ownerState}) => ({
                                textTransform: 'none',

                                ...(ownerState.size === "medium" && ({
                                    fontSize: '0.8rem',
                                })),

                                ...(ownerState.variant === 'contained' && ({
                                    borderRadius: 30,

                                    ...((ownerState.color === 'secondary' || ownerState.color === 'success') && {
                                        color: "#000",
                                    }),
                                    ...((ownerState.color === 'primary') && {
                                        background: gColor(theme, "#f08049", "#f08049"),
                                        color: "#fff",
                                        "&:hover": {
                                            opacity: "0.9"
                                        }
                                    }),
                                    ...((ownerState.color === 'secondary') && {
                                        backgroundColor: "#84BF62",
                                        color: "#fff",
                                        "&:hover": {
                                            backgroundColor: isLight ? colors.secondary.dark : colors.secondary.main,
                                        }
                                    }),
                                    ...((ownerState.color === 'success') && {
                                        backgroundColor: isLight ? colors.secondary.main : muiTheme.palette.success.dark,

                                        "&:hover": {
                                            backgroundColor: isLight ? colors.secondary.dark : muiTheme.palette.success.main,
                                        },
                                        "&.Mui-disabled": {
                                            backgroundColor: isLight ? colors.secondary.main : muiTheme.palette.success.dark,
                                        }
                                    })
                                })),
                            }),
                        }
                    },
                    MuiCard: {
                        styleOverrides: {
                            root: () => ({
                                borderRadius: CARD_BORDER_RADIUS,
                                backgroundColor: getCardBg(isLight),
                                boxShadow: "0px 3px 6px #00000029",
                            })
                        }
                    },
                    MuiPaper: {
                        styleOverrides: {
                            root: () => ({
                                borderRadius: CARD_BORDER_RADIUS,
                                backgroundColor: getBgGrayCard(isLight)
                            })
                        }
                    }
                },

            })
            return responsiveFontSizes(theme)
        },
        [mode],
    );
}


export default useBaseTheme
