import {toast, ToastOptions} from 'react-toastify';

export default function ToastGenerator(message: string, type: "info" | "warning" | "success" | "error" = "warning", props?: ToastOptions) {
    if (type === "info")
        return toast.info(message, props);
    if (type === "success")
        return toast.success(message, props);
    if (type === "warning")
        return toast.warning(message, props);
    if (type === "error")
        return toast.error(message, props);
    return toast(message, props);
}
