import ReactHtmlParser from 'html-react-parser'


export const tryIt = <T>(func: () => T): T | undefined => {
    try {
        return func()
    } catch {
    }
    return undefined
}

export const tryItWithDefault = <T>(func: () => T, defaultValue: T): T => tryIt(func) || defaultValue

export function htmlParser(html: string) {
    if (!html)
        return ''
    return ReactHtmlParser(html)
}

//USD
type convertMoneyOptions = {
    currency?: "GBP",
    maximumSignificantDigits?: number
}

export function convertMoney(
    num: number,
    {
        currency = "GBP",
        maximumSignificantDigits
    }: convertMoneyOptions = {}): string {
    if (num !== 0 && !num)
        return ""
    return new Intl.NumberFormat("en-Us", {
        style: "currency",
        currency: currency,
        maximumSignificantDigits: maximumSignificantDigits ? maximumSignificantDigits : undefined
    }).format(num);
}


export function arrayPushIfNotExist<T>(array: T[], newItem: T): T[] {
    array.indexOf(newItem) === -1 && array.push(newItem);
    return [...array]
}


export const getBaseDomain = () => {
    if (window.location.host.includes("localhost:3003")) {
        return "localhost"
    }
    return process.env.REACT_APP_BASE_DOMAIN
}
