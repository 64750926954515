import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Rout} from "./TYPE";
import AuthRouts from "./AuthRouts";
// import { useSelector } from "react-redux";
// import { checkPermissions, RootState } from "../../../redux/helper";
import PERM from "../PERM";
import {deniedPermission} from "../TYPE";
import {CircularProgress} from "@mui/material";

type pagePermissions = {
    rout: Rout;
};

export default function PagePermission({
                                           rout: {permissions, redirect, Element},
                                       }: pagePermissions) {
    const navigate = useNavigate();
    // const user = useSelector((state: RootState) => state.user);
    const user = undefined;
    const [permCheck, setPermCheck] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        // if (user === null) {
        //   return;
        // }

        // const check = checkPermissions(user, permissions);
        const check: deniedPermission = false;

        if (check === false) {
            setPermCheck(true);
            return;
        }
        if (check === PERM.LOGOUT_REQUIRE._ || check === PERM.LOGIN_REQUIRE._) {
            setPermCheck(false);
            return;
        }
        navigate(redirect);
    }, [navigate, permissions, redirect, user]);


    if (permCheck === undefined) return <React.Fragment/>;

    if (!permCheck) return <AuthRouts/>;

    return (
        <Fragment>
            <React.Suspense fallback={<Loading/>}>
                <Element/>
            </React.Suspense>
        </Fragment>
    );
}

export function Loading() {
    return (
        <CircularProgress/>
    );
}
