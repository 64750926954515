const PERM = {
  LOGIN_REQUIRE: {
    _: "LOGIN_REQUIRE",
  },
  CHART: {
    _: "CART",
  },
  DEBUG: {
    _: "DEBUG",
  },
  LOGOUT_REQUIRE: {
    _: "LOGOUT_REQUIRE",
  },
};

export default PERM;
