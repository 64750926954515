import React, {useEffect} from 'react';
import {Modal} from "@mui/material";
import {accessTokenCookie, refreshTokenCookie} from "../common/Cookie";
import {getStore} from "../redux/store";
import {updateToken} from "../redux/actions/tokenAction";


const loginIframe = window.location.host.includes("localhost:3003") ? "http://localhost:3002" : process.env.REACT_APP_AUTH_API

export default function Login({open}: { open?: boolean }) {
    // const dispatch = useDispatch()
    // const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            let lastCookie = ""
            const interval = setInterval(() => {
                if (document.cookie === lastCookie) {
                    return
                }
                lastCookie = document.cookie;
                const accessToken = accessTokenCookie.get()
                const refreshToken = refreshTokenCookie.get()
                if (!(accessToken && refreshToken))
                    return;
                try {
                    getStore().dispatch(updateToken(accessToken, refreshToken));
                } catch (e) {
                    console.error("error", e)
                }
                // dispatch(updateToken(accessToken, refreshToken))
                clearInterval(interval)
            }, 1000)
        }, 2000)

        // window.location.replace(`${process.env.REACT_APP_AUTH_API}?redirect=${window.location.origin}`)
    }, [])

    // || location.pathname !== AUTH_ROUTS.rout
    return (
        <>
            <Modal open={true}>
                <iframe
                    data-cy="login-iframe"
                    className="loginIframe"
                    src={loginIframe}
                    width={"100%"}
                    height={"100%"}/>
            </Modal>
        </>
    )

    // return (<React.Fragment/>)
    //
    // const [disable, setDisable] = useState(false)
    // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     const entries = data.entries
    //     // @ts-ignore
    //     const username = entries[0][1]
    //     // @ts-ignore
    //     const password = entries[1][1]
    //     if (!(username && password))
    //         return
    //     setDisable(true)
    //     POST(API.Auth.Login.api, {
    //         body: {
    //             username,
    //             password,
    //         },
    //     })
    //         .then((res) => {
    //         })
    //         .finally(() => setDisable(false));
    // };
    //
    // return (
    //     <Container maxWidth="xs">
    //         <CssBaseline/>
    //         <Box
    //             sx={{
    //                 marginTop: 8,
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 alignItems: 'center',
    //             }}
    //         >
    //             <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
    //                 <i className="fa-solid fa-graduation-cap"/>
    //             </Avatar>
    //             <Typography component="h1" variant="h5">
    //                 Sign in
    //             </Typography>
    //             <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
    //                 <TextField
    //                     disabled={disable}
    //                     margin="normal"
    //                     required
    //                     fullWidth
    //                     label="Phone Number"
    //                     name="username"
    //                     autoFocus
    //                 />
    //                 <TextField
    //                     disabled={disable}
    //                     margin="normal"
    //                     required
    //                     fullWidth
    //                     name="password"
    //                     label="Password"
    //                     type="password"
    //                     id="password"
    //                     autoComplete="current-password"
    //                 />
    //                 <FormControlLabel
    //                     control={<Checkbox value="remember" color="primary"/>}
    //                     label="Remember me"
    //                 />
    //                 <Button
    //                     disabled={disable}
    //                     type="submit"
    //                     fullWidth
    //                     variant="contained"
    //                     sx={{mt: 3, mb: 2}}
    //                 >
    //                     Sign In
    //                 </Button>
    //                 {/*<Grid container>*/}
    //                 {/*    <Grid item xs>*/}
    //                 {/*        <Link href="#" variant="body2">*/}
    //                 {/*            Forgot password?*/}
    //                 {/*        </Link>*/}
    //                 {/*    </Grid>*/}
    //                 {/*    <Grid item>*/}
    //                 {/*        <Link href="#" variant="body2">*/}
    //                 {/*            {"Don't have an account? Sign Up"}*/}
    //                 {/*        </Link>*/}
    //                 {/*    </Grid>*/}
    //                 {/*</Grid>*/}
    //             </Box>
    //         </Box>
    //     </Container>
    // );
}
