import {useSelector} from "react-redux";
import {REMOVE_USER, UPDATE_USER} from "../actions/userAction";
import {getStore} from "../store";

/*
user === undefined request to get user data
user === null user is logout
user === user user is login
 */
export const defaultUserData = null;

const userReducers = function (state = defaultUserData, {type, payload}) {
    switch (type) {
        case UPDATE_USER: {

            return {
                ...state,
                ...payload,
            };
        }
        case REMOVE_USER: {
            return false;
        }
        default:
            return state;
    }
};

export default userReducers;

export function useUser() {
    return useSelector((state) => state.user);
}

export function useStoreUser() {
    return getStore().getState().user;
}
